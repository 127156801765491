import React from 'react'
import { Link } from 'gatsby'

import { siteMetadata } from '../../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

class Success extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Contact-success" />
        <section className="text-center">
          <h2 className="title" style={{ 'font-size': '1.4rem' }}>
            お問い合わせありがとうございました
          </h2>
          {/* <span>内容確認メールを送付いたしましたのでご確認ください。</span> */}
          <Link className="nav-link" to="/?ref=self">
            トップページに戻る
          </Link>
        </section>
      </Layout>
    )
  }
}

export default Success
